
<template>
  <div class="page-box box">
    <template v-if="loadStatus || pageStatus === 'add' || pageStatus === 'change'">
      <div class="info-box">
        <div class="card-num">
          {{ pageStatus === 'info' ? form.bankAccountNo : '尚未添加银行卡' }}
        </div>
        <div class="card-type">
          {{ form.bankName || '储蓄卡' }}
        </div>
        <div class="card-person">
          持卡人: {{ pageStatus === 'info' ? form.userName : '' }}
        </div>
        <div
          v-if="pageStatus === 'info'"
          class="card-del"
          @click="changeBtn"
        >
          更换银行卡
        </div>
      </div>
      
      <div class="list-box box">
        <van-field
          v-model="form.userName"
          :readonly="pageStatus === 'info'"
          label="持卡人姓名"
          placeholder="请输入持卡人名称"
          input-align="right"
        />
        <van-field
          v-model="form.mobile"
          :readonly="pageStatus === 'info'"
          label="手机号"
          placeholder="请输入手机号"
          input-align="right"
        />
        <van-field
          v-model="form.idCardNo"
          :readonly="pageStatus === 'info'"
          label="身份证号"
          placeholder="请输入身份证号"
          input-align="right"
        />
        <van-field
          v-model="form.bankAccountNo"
          :readonly="pageStatus === 'info'"
          label="银行卡号"
          placeholder="请输入银行卡号"
          input-align="right"
        />
      </div>

      <van-button
        v-show="pageStatus === 'add'"
        class="_btn"
        color="#51B464"
        round
        type="info"
        @click="nextFun"
      >
        确认添加
      </van-button>
      <!-- 按钮 -->
      <div
        v-show="pageStatus === 'change'"
        class="btn-box box"
      >
        <van-button
          class="btn"
          color="#51B464"
          plain
          @click="cancelChange"
        >
          取消
        </van-button>
        <van-button
          class="btn"
          color="#51B464"
          type="info"
          @click="nextFun('change')"
        >
          确定
        </van-button>
      </div>
    </template>

    <div
      v-else
      class="box"
    >
      <div class="none-box box">
        <van-empty
          class="none-image"
          :image="require('@/assets/img/none-img.png')"
          description="您还未绑定银行卡，请先添加银行卡。"
        />
      </div>
      <van-button
        class="_btn"
        icon="plus"
        color="#51B464"
        round
        type="info"
        @click="actionBtn('add')"
      >
        添加银行卡
      </van-button>
    </div>

    <!-- 银行卡 code 码 -->
    <van-overlay
      style="background: rgba(0,0,0,0.3)"
      :show="show"
      @click="show = false"
    >
      <div
        class="wrapper"
        @click.stop="show = false"
      >
        <div
          class="block"
          @click.stop
        >
          <div class="text-a">
            请填写手机短信验证码
          </div>
          <div class="text-b">
            已发送到{{ form.mobile }}
          </div>
          <van-field
            v-model="code"
            maxlength="6"
            type="number"
            class="text-c"
            label="验证码"
          />
          <van-button
            class="code-btn"
            color="#51B464"
            round
            type="info"
            @click="bindCardBtn"
          >
            提交
          </van-button>
        </div>
      </div>
    </van-overlay>

    <!-- 注册 code -->
    <regBox
      ref="regBox"
      @codeEvent="codeEvent"
    ></regBox>
  </div>
</template>

<script>
import regBox from '@/components/common/regBox.vue'
export default {
  name: 'Test',
  components: {
    regBox
  },
  data () {
    return {
      pageStatus: 'info',
      show: false,
      code: '',
      form: {
        mobile: '',
        bankAccountNo: '',
        idCardNo: '',
        userName: ''
      },
      loadStatus: false,
      showReg: false,
      requestNo: ''
    }
  },
  computed: {
  },
  created () {
    this.checkReg()
    this.getBankList()
  },
  methods: {
    async getBankList () {
      let res = await this.$http.userApi.bankList({
        pageNo: 1,
        pageSize: 999
      })
      if (res.msg && res.msg.data && res.msg.data[0]) {
        this.form = res.msg.data[0] || {}
        this.loadStatus = true
      }
      console.log(res, 'res')
    },
    async actionBtn (msg) {
      if (await this.checkReg()) {
        this.code = ''
        this.form = {
          mobile: '',
          bankAccountNo: '',
          idCardNo: '',
          userName: ''
        }
        this.pageStatus = msg
      }
    },
    async sendCode () {
      if (!this.form.mobile || this.form.mobile.length !== 11) {
        this.$toast('请输入正确手机号')
      } else if (!this.form.bankAccountNo || !this.form.idCardNo || !this.form.userName) {
        this.$toast('请完善相关信息')
      } else {
        let res = await this.$http.userApi.sendCode({mobile: this.form.mobile})
        this.show = true
      }
    },
    // 校验参数
    filterParams () {
      let data = {
        userName: this.form.userName,
        bankAccountNo: this.form.bankAccountNo,
        idCardNo: this.form.idCardNo,
        mobile: this.form.mobile
      }
      if (!data.bankAccountNo) {
        this.$toast('请填写银行卡号')
        return false
      } else if (!data.idCardNo) {
        this.$toast('请填写身份证号')
        return false
      } else if (!data.mobile) {
        this.$toast('请填写手机号')
        return false
      } else if (!data.userName) {
        this.$toast('请填写姓名')
        return false
      } else {
        return data
      }
    },
    async nextFun () {
      let url = 'bankBind'
      if (this.pageStatus === 'change') {
        url = 'bankChange'
      }
      if (this.form.mobile.length !== 11) {
        this.$toast('请输入正确手机号')
        return
      }
      let data = this.filterParams()
      if (data) {
        this.$toast.loading({
          message: '加载中',
          forbidClick: true
        });

        let res = await this.$http.userApi[url](data)
        if (res.code === 200) {
          this.$toast.clear()
          this.show = true
          this.requestNo = res.msg.requestNo || ''
        }
      }
    },
    async bindCardBtn () {
      let url = 'bankBindConfirm'
      if (this.pageStatus === 'change') {
        url = 'bankChangeConfirm'
      }
      if (!this.code) {
        this.$toast('请填写验证码')
      } else {
        let data = {
          requestNo: this.requestNo,
          smsCode: this.code
        }
        this.$toast.loading({
          message: '加载中',
          forbidClick: true
        });
        let res = await this.$http.userApi[url](data)
        if (res.code === 200) {
          this.$toast.success('绑定成功')
          this.show = false
          this.pageStatus = 'info'
          this.getBankList()
        }
      }
    },
    // 取消换绑
    async cancelChange () {
      await this.getBankList()
      this.pageStatus = 'info'
    },
    // 确认换绑
    async changeBtn () {
      this.code = ''
      this.form.bankAccountNo = ''
      this.pageStatus = 'change'
    },
    // 全款支付
    async checkReg () {
      let res = await this.$http.orderApi.checkFullOrder({})
      if (res.code === 200) {
        if (res.msg.reg == '0') {
          // this.showReg = true
          this.checkData = res.msg || {}
          this.$refs.regBox.show()
          return false
        }
      }
      return true
    },
    codeEvent (data) {
      console.log(data)
      if (data.msg == 'success') {
        
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  .info-box {
    width: 335px;
    height: 110px;
    margin: 15px auto 45px;
    background: url('../../../assets/img/bank-bg.png') no-repeat center center;
    background-size: 100% 100%;
    padding: 12px 20px 0;
    color: #fff;
    position: relative;
    .card-num {
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
    }
    .card-type {
      font-size: 12px;
      line-height: 28px;
      padding-bottom: 4px;
      border-bottom: 0.5px solid #fff;
    }
    .card-person {
      margin-top: 9px;
      font-size: 15px;
      font-weight: bold;
      line-height: 18px;
    }
    .card-del {
      width: 94px;
      height: 30px;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 2px;
      font-size: 14px;
      position: absolute;
      right: 20px;
      bottom: 9px;
      text-align: center;
      line-height: 28px;
    }
  }
  // ._btn {
  //   width: 346px;
  //   height: 40px;
  //   color: #fff;
  //   position: fixed;
  //   left: 50%;
  //   bottom: 30px;
  //   margin-left: -173px;
  // }
  // 
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 294px;
      height: 242px;
      background: #fff;
      padding-top: 30px;
      text-align: center;
      border-radius: 5px;
      .text-a {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .text-b {
        line-height: 19px;
        margin-bottom: 30px;
        color: #949494;
        font-size: 14px;
      }
      .text-c {
        text-align:left;
      }
      .code-btn {
        width: 150px;
        height: 40px;
        font-size: 15px;
        margin-top: 36px;
      }
    }
  }

  .none-box {
    padding-top: 135px;
    .none-image {
      /deep/ img {
        widows: 150px;
        height: 115px;
      }
    }
  }
  ._btn {
    width: 200px;
    height: 40px;
    color: #fff;
    position: fixed;
    left: 50%;
    bottom: 16px;
    margin-left: -100px;
  }
  .btn-box {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 6px;
    text-align: center;
    background: #fff;
    z-index: 1;
    .btn {
      width: 166px;
      margin: 0 5px;
    }
  }
}
</style>
